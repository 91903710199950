"use strict";

import axios from "axios";
var baseControllerAddress = 'emailAccount/';

export default {
    create: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .catch(function (error) {
                alert(error);
            });
    },
    getEmailAccountList: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getEmailAccount: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    updateEmailAccount: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteEmailAccount: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteEmailAccount/" + id)
            .catch(function (error) {
                alert(error);
            });
    },
    getAllFreeEmailAccounts: () => {
        return axios.get('/api/' + baseControllerAddress + "getAllFreeEmailAccounts")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
}