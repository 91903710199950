<template>
    <v-container fluid>
        <p v-if="!mailTemplateList"><em>{{ $t('common_loading') }}...</em></p>
        <!-- NAVIGATION TABS -->
        <v-container fluid>
            <div class="tabs-navigation-card">
                <v-tabs class="tabs-navigation"
                        v-model="currentItem"
                        slider-color="demex_primary">
                    <v-container fluid class="grey lighten-5">
                        <v-row>
                            <v-col>
                                <v-tab href="#tab-templates" class="tabs-navigation-item" style="cursor: pointer">
                                    <center>
                                        <v-icon>mdi-gate</v-icon>
                                        <br />
                                        Mallar
                                    </center>
                                </v-tab>
                            </v-col>
                            <v-col>
                                <v-tab href="#tab-accounts" class="tabs-navigation-item" style="cursor: pointer">
                                    <center>
                                        <v-icon>mdi-account</v-icon>
                                        <br />
                                        Konton
                                    </center>
                                </v-tab>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tabs>
            </div>
        </v-container>
            <!-- NAVIGATION TABS -->
            <!--<v-tabs v-model="currentItem"
                    slider-color="primary">
                <v-tab href="#tab-templates">
                    Mallar
                </v-tab>
                <v-tab href="#tab-accounts">
                    Konton
                </v-tab>

            </v-tabs>-->
            <v-tabs-items v-model="currentItem">
                <!-- GATE TAB -->
                <v-tab-item value="tab-templates">
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col>
                                <h1>
                                    {{ $t('admin_dashboard_mailTemplates_title') }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" lg="1" md="1"
                                   style="margin-top: 12px;">
                                {{ pagination.totalListCount }} st hittade
                            </v-col>
                            <v-col cols="12" lg="1" md="1">
                                <!--append-icon="mdi-magnify"-->
                                <v-text-field v-model="searchField"
                                              @input="searchWithText()"
                                              :loading="searchFieldIsLoading"
                                              :label="$t('common_search')"
                                              single-line
                                              dense
                                              outlined
                                              hide-details></v-text-field>
                            </v-col>
                            <v-col align="end">
                                <v-btn color="success"
                                       elevation="2"
                                       large
                                       @click="newMailTemplate()">
                                    <v-icon class="pr-3">
                                        mdi-plus
                                    </v-icon>
                                    {{ $t('common_create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-data-table :headers="headers"
                                  @click:row="editMailTemplate"
                                  :items="mailTemplateList"
                                  :options.sync="options"
                                  :server-items-length="pagination.totalListCount"
                                  :loading="loading"
                                  class="elevation-1"
                                  :hide-default-footer="true">
                    </v-data-table>
                </v-tab-item>

                <v-tab-item value="tab-accounts">
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col>
                                <h1>
                                    {{ $t('admin_dashboard_emailAccount_title') }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" lg="1" md="1"
                                   style="margin-top: 12px;">
                                {{ pagination.totalListCount }} st hittade
                            </v-col>
                            <v-col cols="12" lg="1" md="1">
                                <!--append-icon="mdi-magnify"-->
                                <v-text-field v-model="searchField"
                                              @input="searchWithText()"
                                              :loading="searchFieldIsLoading"
                                              :label="$t('common_search')"
                                              single-line
                                              dense
                                              outlined
                                              hide-details></v-text-field>
                            </v-col>
                            <v-col align="end">
                                <v-btn color="success"
                                       elevation="2"
                                       large
                                       @click="newEmailAccount()">
                                    <v-icon class="pr-3">
                                        mdi-plus
                                    </v-icon>
                                    {{ $t('common_create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-data-table :headers="emailAccountHeaders"
                                  @click:row="editEmailAccount"
                                  :items="emailAccountList"
                                  :options.sync="options"
                                  :server-items-length="pagination.totalListCount"
                                  :loading="loading"
                                  class="elevation-1">
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
    </v-container>
</template>

<style scoped>
    a[aria-selected="true"] {
        color: #003440 !important;
        font-weight: bold;
    }

    .tabs-navigation-card {
        padding: 25px;
        background-color: #b8c3c7;
        border-radius: 7px;
    }

    /deep/ .v-btn__content {
        color: #f3f2e8 !important;
    }

    table, th, td {
        padding: 10px;
    }

    /deep/ .theme--light.v-tabs > .v-tabs-bar {
        background-color: #b8c3c7;
    }

    /deep/ .v-application .grey.lighten-5 {
        background-color: #b8c3c7 !important;
        border-color: #b8c3c7 !important;
    }

    .tabs-navigation-item {
        margin-top: -40px;
    }

    /deep/ .tabs-navigation .row {
        min-height: 200px !important;
        background-color: #b8c3c7;
        padding: 30px;
    }

    .hideHint {
        visibility: hidden;
        display: none;
    }
</style>
<style scoped>

    /* makes arrows next to table headers (filter buttons) visible acc. to  Annes design, it is not editable */
    /deep/ .v-icon {
        opacity: 1 !important;
    }

    /* removes borders top an bot for every generated row */
    /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
        border-top: 0px !important;
        border-bottom: 0px !important;
    }

        /* adds a border from top to bottom between every piece of horizontal data */
        /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:last-child), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
            border-right: 1px solid #5a5f62;
        }

    /* adds yellow background for every 2 rows */
    /deep/ tr:nth-child(even) {
        background-color: #f5f4ec !important;
    }

    /* gives table headers rounder corners */
    /deep/ tr th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    /* gives table headers rounder corners */
    /deep/ tr th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    /deep/ .v-data-table > .v-data-table__wrapper > table > thead {
        background-color: #707070;
    }

    /deep/ .v-text-field {
        min-width: 120px;
    }

    /* vertical lines between table header titles */
    /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-right: 1px solid #5a5f62 !important;
    }
</style>
<script>
    import mailTemplateService from '@/services/mailTemplateService';
    import emailAccountService from '@/services/emailAccountService';
    import router from '@/router';

    export default {
        name: "mailTemplateList",
        data: () => ({
            currentItem: 'tab-templates',


            search: '',
            loading: true,
            options: {},

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            mailTemplateList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,

            emailAccountList: [],
        }),
        computed: {
            headers() {
                return [
                    {
                        text: 'id',
                        align: 'start',
                        sortable: true,
                        value: 'id',
                    },
                    {
                        text: this.$t('name'),
                        sortable: true,
                        value: 'name',
                    },
                    {
                        text: this.$t('bccEmailAddresses'),
                        sortable: true,
                        value: 'bccEmailAddresses',
                    },
                    {
                        text: this.$t('subject'),
                        sortable: true,
                        value: 'subject',
                    },
                    {
                        text: this.$t('isActive'),
                        sortable: true,
                        value: 'isActive',
                    },
                ]
            },
            emailAccountHeaders() {
                return [
                    {
                        text: 'id',
                        align: 'start',
                        sortable: true,
                        value: 'id',
                    },
                    {
                        text: this.$t('DisplayName'),
                        sortable: true,
                        value: 'displayName',
                    },
                    {
                        text: this.$t('Email'),
                        sortable: true,
                        value: 'email',
                    },
                    {
                        text: this.$t('Username'),
                        sortable: true,
                        value: 'username',
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.currentItem == 'tab-templates' ? this.getMailTemplates() : this.getEmailAccounts();
                },
                deep: true,
            },
        },
        methods: {
            newMailTemplate() {
                router.push({ name: 'editMailTemplate', params: { mailTemplateId: 0 } })
            },
            editMailTemplate(e) {
                router.push({ name: 'editMailTemplate', params: { mailTemplateId: parseInt(e.id) } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getMailTemplates();
                }, 1000);
            },
            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getMailTemplates();
            },
            getMailTemplates() {
                mailTemplateService.getMailTemplateList("FilterMailTemplateList", {
                    Count: this.options.itemsPerPage,
                    Items: [],
                    SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    SortDesc: this.options.sortDesc[0],
                    Page: this.options.page
                })
                    .then((data) => {
                        this.mailTemplateList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.page = data.page;
                        this.pagination.totalListCount = data.totalListCount;

                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            // ------------------------------------ email account -----------------------------
            newEmailAccount() {
                router.push({ name: 'editEmailAccount', params: { emailAccountId: 0 } })
            },
            editEmailAccount(e) {
                router.push({ name: 'editEmailAccount', params: { emailAccountId: parseInt(e.id) } })
            },
            getEmailAccounts() {
                emailAccountService.getEmailAccountList("FilterEmailAccountList", {
                    Count: this.options.itemsPerPage,
                    Items: [],
                    SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    SortDesc: this.options.sortDesc[0],
                    Page: this.options.page
                })
                    .then((data) => {
                        this.emailAccountList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.page = data.page;
                        this.pagination.totalListCount = data.totalListCount;

                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        }
    }
</script>
