"use strict";

import axios from "axios";
var baseControllerAddress = 'mailTemplate/';

export default {
    create: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .catch(function (error) {
                alert(error);
            });
    },
    getMailTemplateList: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getMailTemplate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    updateMailTemplate: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteMailTemplate: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteMailTemplate/" + id)
            .catch(function (error) {
                alert(error);
            });
    },
    connectEmailAccount: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "connectEmailAccount", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    unconnectEmailAccount: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "unconnectEmailAccount", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    //sendGateInviteEmail: (vm) => {
    //    return axios.post('/api/' + baseControllerAddress + "SendGateInviteEmail", vm)
    //        .catch(function (error) {
    //            alert(error);
    //        });
    //},
}